import { authRoles } from '../../auth/authRoles';
import Loadable from '../../components/Loadable';
import { lazy } from 'react';
// import AddBilty from './addbilty/AddBilty';

const Consignor = Loadable(lazy(() => import('./consignor/consignor')));
const Consignee = Loadable(lazy(() => import('./consignee/consignee')));
const City = Loadable(lazy(() => import('./city/City')));
const CreateRoutes = Loadable(lazy(() => import('./routes/CreateRoutes')));
const Driver = Loadable(lazy(() => import('./driver/Driver')));
const Vehicle = Loadable(lazy(() => import('./vehicle/Vehicle')));
const Quotation = Loadable(lazy(() => import('./quotation/Quotation')));
// const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
// const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
// const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));

const consignorRoutes = [
  { path: '/master/consignor', element: <Consignor /> },
  { path: '/master/consignee', element: <Consignee /> },
  { path: '/master/city', element: <City /> },
  { path: '/master/routes', element: <CreateRoutes /> },
  { path: '/master/driver', element: <Driver /> },
  { path: '/master/Vehicle', element: <Vehicle /> },
  { path: '/master/quotation', element: <Quotation /> , auth: authRoles.SA },
//   { path: '/session/signin', element: <JwtLogin /> },
//   { path: '/session/forgot-password', element: <ForgotPassword /> },
//   { path: '/session/404', element: <NotFound /> },
];

export default consignorRoutes;
