import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// const socket = io(imagesBaseUrl);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <CssBaseline />
      {/* <SocketContext.Provider value={socket}> */}
        <App />
      {/* </SocketContext.Provider> */}
    </BrowserRouter>
  </StyledEngineProvider>
);

serviceWorkerRegistration.register();
