import Loadable from '../../components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));

const dashboardRoutes = [
  { path: '/admindashboard', element: <Dashboard />, auth: authRoles.GUEST },
];

export default dashboardRoutes;
