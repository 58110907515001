import {
  CREATE_NOR,
  GET_NOR,
  DELETE_NOR,
  EDIT_NOR,
  GET_PKG,
  CREATE_PKG,
} from '../constant/constant';

const initialState = [];

export const NorReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_NOR: {
      return [...action.payload];
    }

    case CREATE_NOR: {
      return [...state, action.payload];
    }

    case DELETE_NOR: {
      return state.filter(({ _id }) => _id !== action.payload._id);
    }
    case EDIT_NOR: {
      return state.map((state) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            ...action.payload,
          };
        } else {
          return state;
        }
      });
    }
    default: {
      return [...state];
    }
  }
};

export const PkgReducer = function (state = [], action) {
  switch (action.type) {
    case GET_PKG: {
      return [...action.payload];
    }

    case CREATE_PKG: {
      return [...state, action.payload];
    }

    // case DELETE_NOR: {
    //   return state.filter(({ _id }) => _id !== action.payload._id);
    // }
    // case EDIT_NOR: {
    //   return state.map((state) => {
    //     if (state._id === action.payload._id) {
    //       return {
    //         ...state,
    //         ...action.payload,
    //       };
    //     } else {
    //       return state;
    //     }
    //   });
    // }
    default: {
      return [...state];
    }
  }
};
