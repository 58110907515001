import { GET_MEMO_RECEIVE, UPDATE_MEMO_RECEIVE } from "../constant/constant";


const initialValues = {
  driverInfo:{name:''},
  vehicleInfo:{vehiclenumber:''},
  bilties:[]
}


export const ReceiveMemoReducer = function (state = initialValues, action) {
    switch (action.type) {
      case GET_MEMO_RECEIVE: {
        return action.payload;
      }
      case UPDATE_MEMO_RECEIVE:
      return {
        ...state,
        bilties: state.bilties.map(bilty =>
          bilty.biltynumber === action.payload.biltynumber
            ? { ...bilty, status: action.payload.status }
            : bilty
        )
      };


  
    //   case CREATE_CITY: {
    //     return [...state,action.payload];
    //   }
  
    //   case DELETE_CITY: {
    //     return state.filter(({ _id }) => _id !== action.payload._id);
    //   }
    //   case EDIT_CITY: {
    //     return state.map((state) => {
    //       if (state._id === action.payload._id) {
    //         return {
    //           ...state,
    //           ...action.payload,
    //         };
    //       } else {
    //         return state;
    //       }
    //     });
    //   }
      default: {
        return state;
      }
    }
  };