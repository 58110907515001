import {
    CREATE_NEE,
    GET_NEE,
    DELETE_NEE,
    EDIT_NEE,
  } from '../constant/constant';
  
  const initialState = [];
  
  const NeeReducer = function (state = initialState, action) {
    switch (action.type) {
      case GET_NEE: {
        return [...action.payload];
      }
  
      case CREATE_NEE: {
        return [...state,action.payload];
      }
  
      case DELETE_NEE: {
        return state.filter(({ _id }) => _id !== action.payload._id);
      }
      case EDIT_NEE: {
        return state.map((state) => {
          if (state._id === action.payload._id) {
            return {
              ...state,
              ...action.payload,
            };
          } else {
            return state;
          }
        });;
      }
      default: {
        return [...state];
      }
    }
  };
  
  export default NeeReducer;
  