import {MEMO_PRINT_DATA, PRINT_NUMBER } from "../constant/constant";


  export const printNumberReducer = function (state = [], action) {
    switch (action.type) {
      case PRINT_NUMBER: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  };
  



const memoPrint = 
 [ {
    driverInfo:{name:''},
    vehicleInfo:{},
    bilties:[],
  }]


  export const MemoPrintReducer = function (state = memoPrint, action) {
    switch (action.type) {
      case MEMO_PRINT_DATA: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  };
