import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const AccDashboard = Loadable(lazy(() => import('./AccDashboard')));
const Payment = Loadable(lazy(() => import('./Payment')));
const Receipt = Loadable(lazy(() => import('./Receipt')));
const AccMaster = Loadable(lazy(() => import('./AccMaster')));
const Ledger = Loadable(lazy(() => import('./Ledger')));

const accRoutes = [
  { path: '/accdashboard', element: <AccDashboard /> },
  { path: '/payment', element: <Payment /> },
  { path: '/receipt', element: <Receipt /> },
  { path: '/accmaster', element: <AccMaster /> },
  { path: '/ledger', element: <Ledger /> },
];

export default accRoutes;
