import { CREATE_DRIVER, CREATE_VEHICLE, DELETE_DRIVER, DELETE_VEHICLE, EDIT_DRIVER, EDIT_VEHICLE, GET_DRIVER, GET_VEHICLE } from '../constant/constant';

const initialState = [];

export const DriverReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DRIVER: {
      return [...action.payload];
    }

    case CREATE_DRIVER: {
      return [...state, action.payload];
    }

    case EDIT_DRIVER: {
      return state.map((state) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            ...action.payload,
          };
        } else {
          return state;
        }
      });
    }

      case DELETE_DRIVER: {
        return state.filter(({ _id }) => _id !== action.payload._id);
      }
    default: {
      return [...state];
    }
  }
};

  export const VehicleReducer = function (state = [], action) {
    switch (action.type) {
      case GET_VEHICLE: {
        return [...action.payload];
      }

      case CREATE_VEHICLE: {
        return [...state,action.payload];
      }

      
      case EDIT_VEHICLE: {
        return state.map((state) => {
          if (state._id === action.payload._id) {
            return {
              ...state,
              ...action.payload,
            };
          } else {
            return state;
          }
        });
      }

      case DELETE_VEHICLE: {
        return state.filter(({ _id }) => _id !== action.payload._id);
      }
      
      default: {
        return [...state];
      }
    }
  };
