import { ALL_BILTY_DATA } from "../constant/constant";



export const AllBiltyReport = function (state = [], action) {
    switch (action.type) {
      case ALL_BILTY_DATA:
        return action.payload;
      default: {
        return state;
      }
    }
  };