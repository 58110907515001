import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const PendingPod = Loadable(lazy(() => import('./view/PendingPod')));

const driverRoutes = [
  { path: '/pendingpod', element: <PendingPod /> },
];

export default driverRoutes;
