import Loadable from '../../components/Loadable';
import { lazy } from 'react';


const CustEnquiry = Loadable(lazy(() => import('./CustEnquiry')));

const CustRouter = [
  { path: '/customer_enquiry', element: <CustEnquiry /> },
 
];

export default CustRouter;
