import { ALL_MEMO_DATA } from "../constant/constant";

export const AllMemoReport = function (state = [], action) {
    switch (action.type) {
      case ALL_MEMO_DATA:
        return action.payload;
      default: {
        return state;
      }
    }
  };