import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Profile = Loadable(lazy(() => import('./Profile')));
const JwtRegister = Loadable(lazy(() => import('../sessions/JwtRegister')));
const UsersList = Loadable(lazy(() => import('./UsersList')));

const profileRoutes = [
  { path: '/user-profile', element: <Profile /> },
  { path: '/signup', element: <JwtRegister />},
  { path: '/userlist', element: <UsersList />},
];

export default profileRoutes;
