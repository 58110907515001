import { CREATE_ACCOUNT, GET_ACCOUNTS } from '../constant/constant';

export const AccMasterReducer = function (state = [], action) {
  switch (action.type) {
    case GET_ACCOUNTS: {
      return [...action.payload];
    }

    case CREATE_ACCOUNT: {
      return [...state, action.payload];
    }

    // case DELETE_CITY: {
    //   return state.filter(({ _id }) => _id !== action.payload._id);
    // }
    // case EDIT_CITY: {
    //   return state.map((state) => {
    //     if (state._id === action.payload._id) {
    //       return {
    //         ...state,
    //         ...action.payload,
    //       };
    //     } else {
    //       return state;
    //     }
    //   });
    // }
    default: {
      return [...state];
    }
  }
};
