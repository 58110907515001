import { SINGLE_MEMO_DATA } from '../constant/constant';

const initialValue = {
  driverInfo: null,
  vehicleInfo: null,
  driverAdvance: 0,
  trip: 'trip',
};

export const MemoSingle = function (state = initialValue, action) {
  switch (action.type) {
    case SINGLE_MEMO_DATA: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
