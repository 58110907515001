import { Loading_API } from "../constant/constant";

const initialState = {loading:false};

const LoadingReducer = function (state = initialState, action) {
  switch (action.type) {
    case Loading_API: {
      return {loading:action.payload};
    }
    default: {
      return state;
    }
  }
};

export default LoadingReducer;
