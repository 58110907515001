import {
  CREATE_CITY,
  CREATE_ROUTE,
  DELETE_CITY,
  DELETE_ROUTE,
  EDIT_CITY,
  EDIT_ROUTE,
  GET_CITY,
  GET_ROUTE,
} from '../constant/constant';

const initialState = [];

export const CityReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CITY: {
      return [...action.payload];
    }

    case CREATE_CITY: {
      return [...state, action.payload];
    }

    case DELETE_CITY: {
      return state.filter(({ _id }) => _id !== action.payload._id);
    }
    case EDIT_CITY: {
      return state.map((state) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            ...action.payload,
          };
        } else {
          return state;
        }
      });
    }
    default: {
      return [...state];
    }
  }
};

export const RouteReducer = function (state = [], action) {
  switch (action.type) {
    case GET_ROUTE: {
      return [...action.payload];
    }

    case CREATE_ROUTE: {
      return [...state, action.payload];
    }

    case DELETE_ROUTE: {
      return state.filter(({ _id }) => _id !== action.payload._id);
    }
    case EDIT_ROUTE: {
      return state.map((state) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            ...action.payload,
          };
        } else {
          return state;
        }
      });
    }
    default: {
      return [...state];
    }
  }
};
